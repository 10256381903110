<div class="grid-row" [class.is-read]="notification.isReadStatus">
    <div class="marker">
        <span class="unread-marker"></span>
    </div>

    <mat-icon class="icon" [svgIcon]="icon"></mat-icon>

    <!-- subject -->
    <div class="subject margin-left-1">
        {{ notification.data.subject }}
        @if (!isMobile) {
            <ui-pill
                class="pill margin-left-1"
                *ngIf="notification.status && notification.link"
                [type]="notificationStatusPillType"
                >{{ notification.status }}</ui-pill
            >
        }
    </div>

    <!-- show the truncated notification -->
    <div
        *ngIf="notification?.data?.message?.length > CUT_OFF_LENGTH"
        class="message margin-left-1"
        [innerHTML]="notification.data.message | nl2br | limitTo: 100 | stripTags"
    ></div>

    <div
        *ngIf="notification.data.message.length <= CUT_OFF_LENGTH"
        class="message margin-left-1"
        [innerHTML]="notification.data.message | stripTags | nl2br"
    ></div>

    @if (!isMobile) {
        <div class="notification-date">{{ notification.createdAt | appDate }}</div>
    } @else {
        <div class="datepill">
            <ui-pill
                class="pill margin-left-1"
                *ngIf="notification.status && notification.link"
                [type]="notificationStatusPillType"
                >{{ notification.status }}</ui-pill
            >
            <div class="notification-date">{{ notification.createdAt | appDate }}</div>
        </div>
    }

    <ui-button (click)="$event.stopPropagation()" class="meatball" [matMenu]="meatball" size="no-padding">
        <div class="sr-only">{{ notification.data.subject }} {{ 'screen-reader-only.moreActions' | translate }}</div>
        <mat-icon svgIcon="meatballHorizontal"></mat-icon>
    </ui-button>

    <mat-menu #meatball>
        <button mat-menu-item (click)="handleToggleRead(notification)">
            <ng-container *ngIf="notification.isReadStatus; else markRead"
                >{{ 'dashboard.markAsUnread' | translate }}
            </ng-container>
            <ng-template #markRead>{{ 'dashboard.markAsRead' | translate }} </ng-template>
        </button>
        <ng-container *ngIf="!notification?.archivedAt">
            <button (click)="handleArchive(notification)" mat-menu-item>{{ 'dashboard.archive' | translate }}</button>
        </ng-container>
        <ng-container *ngIf="notification?.archivedAt">
            <button (click)="handleSendToInbox(notification)" mat-menu-item>
                {{ 'dashboard.sendToInbox' | translate }}
            </button>
        </ng-container>
    </mat-menu>
</div>
