<div class="page-container inner-padding max-width" [class.loading]="isLoading">
    <div class="navigation">
        <app-calendar-nav (selectDate)="onChangeDate($event)"> </app-calendar-nav>

        <div class="filters">
            <app-filter *ngIf="usesFilters" class="bordered" [groups]="filterGroups" (filter)="onFilter()"></app-filter>

            <app-search
                placeholder="{{ 'search' | appTranslate }}"
                [bordered]="true"
                (onSearch)="onSearch($event)"
            ></app-search>
        </div>
    </div>

    <ui-card class="calendar-container" [withSpacing]="false" [withMargin]="false">
        <mwl-calendar-month-view
            [viewDate]="date"
            [headerTemplate]="headerTemplate"
            [events]="scopedEvents"
            [cellTemplate]="customCellTemplate"
            (dayClicked)="onDayClicked($event.day)"
        >
        </mwl-calendar-month-view>
    </ui-card>

    <!--Header-->
    <ng-template #headerTemplate let-days="days" let-locale="locale">
        <div class="cal-cell-row cal-header">
            <div
                class="cal-cell"
                *ngFor="let day of days"
                [class.cal-past]="day.isPast"
                [class.cal-today]="day.isToday"
                [class.cal-future]="day.isFuture"
                [class.cal-weekend]="day.isWeekend"
                [ngClass]="day.cssClass"
            >
                {{ day.date | appDate: 'EEE' | lowercase }}
            </div>
        </div>
    </ng-template>

    <!--Tool tip-->
    <ng-template #tooltipTemplateEvent let-contents="contents" let-event="event">
        <div
            class="cal-tooltip"
            [ngClass]="getPlacement(event.day?.date) === 'right' ? 'cal-tooltip-right' : 'cal-tooltip-left'"
        >
            <!--Tool tip-->
            <ui-card [withMargin]="false" [withSpacing]="false" class="cal-tooltip-inner">
                <h2 class="top">
                    {{ event.event.monthCalendarTooltipDescription }}
                    <span
                        class="ui tiny circular empty label"
                        [class.green]="event.event.status === 'approved'"
                        [class.yellow]="event.event.status === 'pending'"
                        [class.red]="event.event.status === 'denied'"
                    ></span>
                </h2>

                <!--Only shown if an extended description is included-->
                <div class="middle" *ngIf="event.event.description">
                    <div class="icon-container"><img appIcon="JustifyLeft" class="icon" /></div>

                    <div class="extended-description">{{ event.event.description }}</div>
                </div>

                <div class="bottom">
                    <!-- On desktop we only show the tooltip for employee time-off events, not for holidays and blackout periods.
                        However, on mobile we need to show the tooltips for all events because there's not enough room on the calendar cell itself to display all of the information.
                        app-avatar will show a ?? if there's no name, so an *ngIf needed to be added here to hide it if the event doesn't have an employee name
                    -->
                    <app-avatar *ngIf="event.event.firstName" [employee]="event.event"></app-avatar>
                    <div class="title" *ngIf="event.event.firstName">
                        {{ event.event.firstName }} {{ event.event.lastName }}
                    </div>
                    <div class="date">{{ event.event.dateDescription }}</div>
                </div>
            </ui-card>
        </div>
    </ng-template>

    <!--Calendar Cell-->
    <ng-template
        #customCellTemplate
        let-day="day"
        let-openDay="openDay"
        let-locale="locale"
        let-eventClicked="eventClicked"
        let-tooltipTemplate="tooltipTemplate"
    >
        <div class="cal-cell-top">
            <span class="cal-day-number">{{ day.date | calendarDate: 'monthViewDayNumber' : locale }}</span>
        </div>

        <!-- Events -->
        <div *ngIf="maxEventsPerDay[isMobile ? 'mobile' : 'desktop'] as maxEventsNum" class="cal-events">
            <ng-container *ngFor="let event of day?.events | slice: 0 : maxEventsNum; let i = index">
                <div
                    *ngIf="!isMobile && i < maxEventsNum"
                    class="cal-event"
                    (mouseenter)="onHoverEvent()"
                    (click)="onClickEvent(event)"
                    [appCalendarEventColor]="event"
                    [mwlCalendarTooltip]="event.hasToolTip"
                    [tooltipEvent]="{ day: day, event: event }"
                    [tooltipTemplate]="tooltipTemplateEvent"
                    [tooltipPlacement]="getPlacement(day.date)"
                    (mwlClick)="eventClicked.emit({ event: day })"
                >
                    <div class="cal-event-title" [style.color]="event.color.secondary">
                        {{ event.monthCalendarTitle }}
                    </div>
                </div>
                <!-- MOBILE EMPLOYEE TIME-OFF EVENT
                    The display logic here will hide this element if it is greater than the maxEventsPerDay or if it is equal to the max and there are more events to be displayed.
                    ie. If there are < 4 events, all are displayed. If there's exactly 4, all are displayed. But if there are > 4, only 3 are displayed and the 4th becomes an "..." to view more -->
                <app-avatar
                    *ngIf="isMobile && event.firstName && (day.events.length <= maxEventsNum || i < maxEventsNum - 1)"
                    [employee]="event"
                    (mouseenter)="onHoverEvent()"
                    (click)="onClickEvent(event)"
                    [mwlCalendarTooltip]="true"
                    [tooltipEvent]="{ day: day, event: event }"
                    [tooltipTemplate]="tooltipTemplateEvent"
                    tooltipPlacement="top"
                    (mwlClick)="eventClicked.emit({ event: day })"
                    size="mobile-shrink"
                ></app-avatar>
                <!-- MOBILE BLACKOUT OR HOLIDAY EVENT
                    These events don't have an employee attached to them so we don't want to use app-avatar -->
                <div
                    *ngIf="isMobile && !event.firstName && (day.events.length <= maxEventsNum || i < maxEventsNum - 1)"
                    class="other-event"
                    (mouseenter)="onHoverEvent()"
                    (click)="onClickEvent(event)"
                    [mwlCalendarTooltip]="true"
                    [tooltipEvent]="{ day: day, event: event }"
                    [tooltipTemplate]="tooltipTemplateEvent"
                    tooltipPlacement="top"
                    (mwlClick)="eventClicked.emit({ event: day })"
                >
                    <mat-icon svgIcon="notification" />
                </div>
            </ng-container>

            <ng-container *ngIf="day.events.length > maxEventsNum">
                <div *ngIf="isMobile" class="mobile-more-text" (click)="onClickViewMore(day)"><span>...</span></div>
                <div *ngIf="!isMobile" class="view-more-text" (click)="onClickViewMore(day)">
                    {{ day.events.length - maxEventsNum }} more...
                </div>
            </ng-container>
        </div>

        <ui-card *ngIf="selectedDay === day" [withMargin]="false" [withSpacing]="false" class="additional-events">
            <div class="additional-events-title">
                <div class="additional-events-title-date">{{ day.date | appDate: 'EEE, MMMM d' }}</div>
                <div (click)="onCloseAdditionalEvents()"><i class="fal fa-times"></i></div>
            </div>
            <!-- DESKTOP ADDITIONAL EVENTS-->
            <div *ngIf="!isMobile" class="cal-events">
                <div
                    class="cal-event"
                    *ngFor="let event of day?.events"
                    [appCalendarEventColor]="event"
                    (click)="onClickEvent(event)"
                    [mwlCalendarTooltip]="event.hasToolTip"
                    [tooltipEvent]="{ day: day, event: event }"
                    [tooltipTemplate]="tooltipTemplateEvent"
                    [tooltipPlacement]="getPlacement(day.date)"
                >
                    <div class="cal-event-title" [style.color]="event.color.secondary">
                        {{ event.monthCalendarTitle }}
                    </div>
                </div>
            </div>
            <!-- MOBILE ADDITIONAL EVENTS
                Additional events on mobile are shown as a full screen-list of the tooltip content. This makes them easier to browse rather than relying on individual tooltip positioning-->
            <div *ngIf="isMobile" class="mobile-events">
                <ng-container
                    *ngFor="let event of day?.events"
                    [ngTemplateOutlet]="tooltipTemplateEvent"
                    [ngTemplateOutletContext]="{ event: { day: day, event: event } }"
                ></ng-container>
            </div>
        </ui-card>
    </ng-template>
</div>
